import { useLogger, useSwanStyleKeys, useSwanFontKeys, IdentityProvider } from '@vp/ubik-context'
import { CampaignProvider } from '@vp/campaign-callouts'
import { SwanFontNameEnum } from '@vp/swan'
import { useSetPriceConfigs, PageId } from '@vp/price-platform'
import { CouponCalloutProvider, Locale, PageId as RequestPage } from '@vp/coupon-callout'

import PageSections from './components/PageSections'
import { useProductListTracking } from './components/useProductListTracking'
import PageHeaderContainer, { getPageHeaderConfig } from './components/header/PageHeaderContainer'
import ExperimentsProvider from './components/ExperimentsProvider'
import { AppProvider } from './state/AppContext'

import { trackUsedContext } from './utils/tracking/tracking'
import { swanStyleKeys } from './utils/swanStyleKeys'
import { createAuthConfig } from './utils/auth/auth'

import { FragmentProps } from './types/fragment-types'
import DebugInfo from './components/debugComponents/DebugInfo'
import FavoriteHeartContainer from './components/FavoriteHeartContainer'

import {
  primaryContentExperimentData,
  primaryContentExperimentLocales,
  primaryContentExperimentName,
  primaryContentExperimentVariationTest2
} from './constants/primaryContentTestData'
import { formatLocale } from './utils/buildHelpers'

export const Fragment = (props: FragmentProps) => {
  const {
    experimentKey,
    pageData,
    templateConfiguration,
    locale,
    pageDataFromBFF,
    dictionaryEntries,
    merchandisingCampaign,
    merchandisingCategoryId,
    vistacartTenant,
    defaultVatIncl,
    tenant,
    reviewsData,
    treatment,
    variationKey,
    spaceId,
    contentfulId,
    experiments
  } = props
  const logger = useLogger()
  useSwanStyleKeys(swanStyleKeys)
  useSwanFontKeys([
    SwanFontNameEnum.Graphik,
    SwanFontNameEnum.Tiempos,
  ])

  const primaryContentExperimentVariation = experiments?.[primaryContentExperimentName]
  const isPagePartOfPrimarayContentExperiment: boolean = primaryContentExperimentLocales.includes(formatLocale(locale, true)) &&
    primaryContentExperimentData[formatLocale(locale, true)]?.includes(merchandisingCategoryId) &&
    !!primaryContentExperimentVariation

  const { backgroundColor, enableCampaignCallouts, sections, sidebar } = templateConfiguration

  const pageHeaderConfig = getPageHeaderConfig(templateConfiguration)
  const appProviderValue = {
    locale,
    environment: process.env.UBIK_ENVIRONMENT === 'prod' ? 'prod' : 'dev', // todo : think about UBIK_ENVIRONMENT
    enableCampaignCallouts,
    defaultVatIncl,
    dictionaryEntries,
    merchandisingCategoryId,
    backgroundColor: backgroundColor?.name,
    sidebarEnabled: isPagePartOfPrimarayContentExperiment && primaryContentExperimentVariation === primaryContentExperimentVariationTest2 ? false : sidebar?.enabled,
    tenant,
    vistacartTenant,
    mpvIds: pageDataFromBFF?.mpvs?.map((mpv) => mpv?.mpvId) || [],
    uploadFlowMpv: pageDataFromBFF?.uploadFlowMpv,
    referencedFields: pageData.referencedFields,
    reviewsData,
    spaceId,
    contentfulId,
    logger,
    isPagePartOfPrimarayContentExperiment,
    primaryContentExperimentVariation
  }

  useProductListTracking({ sections, mpvs: pageDataFromBFF?.mpvs })
  trackUsedContext(merchandisingCampaign, experimentKey, variationKey, treatment)
  if (isPagePartOfPrimarayContentExperiment) {
    trackUsedContext(merchandisingCampaign, primaryContentExperimentName, primaryContentExperimentVariation, treatment)
  }
  useSetPriceConfigs({
    locale,
    tenant,
    pageId: PageId.Category,
    tracking: {
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${merchandisingCategoryId}:Category Page`,
    },
  })

  return (
    <IdentityProvider auth={createAuthConfig(locale)}>
      <CampaignProvider tenant={tenant} locale={locale}>
        <AppProvider {...appProviderValue}>
          <DebugInfo />
          <ExperimentsProvider>
            <FavoriteHeartContainer>
              <CouponCalloutProvider
                baseConfig={{
                  tenant,
                  locale: formatLocale(locale, true) as Locale,
                  pageId: RequestPage.Category,
                  developmentMode: false,
                }}
              >

                <PageHeaderContainer
                  configuration={pageHeaderConfig}
                  sidebar={templateConfiguration.sidebar}
                  pageDataFromBFF={pageDataFromBFF}
                />
                <PageSections
                  sections={sections}
                  pageCategoryPageV3={pageData}
                  pageDataFromBFF={pageDataFromBFF}
                />
              </CouponCalloutProvider>
            </FavoriteHeartContainer>
          </ExperimentsProvider>
        </AppProvider>
      </CampaignProvider>
    </IdentityProvider>
  )
}
